import React, { useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import "./App.css";

const Signup = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const navigate = useNavigate();

    const handleSignup = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(
                "api/accounts/check-email/",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ email }),
                },
            );
            const data = await response.json();
            if (response.ok) {
                setMessage(data.message);
                navigate("/verify-otp", { state: { email } });
            } else {
                setMessage(data.error);
            }
        } catch (error) {
            setMessage("An error occurred. Please try again.");
        }
    };

    return (
        <div className="Main">
            <header className="Header">
                <Link to="/login" className="header-link">
                    <h1>CS Duck Mark1</h1>
                </Link>
                <div className="Spacer"></div>

                <div className="UserInfo">
                    <p> - </p>
                </div>
            </header>
            <h2>Signup</h2>
            <form onSubmit={handleSignup}>
                <div>
                    <label>Email: &ensp;</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <br></br>
                <button type="submit">Submit</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

const VerifyOTP = () => {
    const [otp, setOtp] = useState("");
    const [message, setMessage] = useState("");
    const location = useLocation();
    const navigate = useNavigate();
    const { email } = location.state;

    const handleVerify = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(
                "api/accounts/verify-otp/",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ email, otp }),
                },
            );
            const data = await response.json();
            if (response.ok) {
                setMessage(data.message);
                navigate("/set-password", { state: { email, otp } });
            } else {
                setMessage(data.error);
            }
        } catch (error) {
            setMessage("An error occurred. Please try again.");
        }
    };

    return (
        <div className="Main">
            <header className="Header">
                <Link to="/login" className="header-link">
                    <h1>CS Duck Mark1</h1>
                </Link>
                <div className="Spacer"></div>

                <div className="UserInfo">
                    <p> - </p>
                </div>
            </header>

            <h2>Verify OTP</h2>
            <form onSubmit={handleVerify}>
                <div>
                    <label>OTP: &ensp;</label>
                    <input
                        type="text"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        required
                    />
                </div>
                <br></br>
                <button type="submit">Submit</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

const SetPassword = () => {
    const [password, setPassword] = useState("");
    const [confirm_password, setConfirmPassword] = useState("");
    const [message, setMessage] = useState("");
    const location = useLocation();
    const navigate = useNavigate();
    const { email, otp } = location.state;

    const handleSetPassword = async (e) => {
        e.preventDefault();

        if (password !== confirm_password) {
            setMessage("Passwords do not match.");
            return;
        }

        try {
            const response = await fetch(
                "api/accounts/create-user/",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ email, otp, password }),
                },
            );
            const data = await response.json();
            if (response.ok) {
                setMessage(data.message);
                navigate("/login");
            } else {
                setMessage(data.error);
            }
        } catch (error) {
            setMessage("An error occurred. Please try again.");
        }
    };

    return (
        <div className="Main">
            <header className="Header">
                <Link to="/login" className="header-link">
                    <h1>CS Duck Mark1</h1>
                </Link>
                <div className="Spacer"></div>

                <div className="UserInfo">
                    <p> - </p>
                </div>
            </header>

            <h2>Set Password</h2>
            <form onSubmit={handleSetPassword}>
                <div>
                    <label>
                        Password: &emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;
                    </label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Confirm Password: &ensp;</label>
                    <input
                        type="password"
                        value={confirm_password}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>
                <br></br>
                <button type="submit">Submit</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

const Accounts = {
    Signup,
    VerifyOTP,
    SetPassword,
};

export default Accounts;
