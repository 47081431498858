import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import { MathJaxContext } from "better-react-mathjax";

import Login from "./Login";
import Chat from "./Chat";
import Accounts from "./Accounts";

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    setUser(storedUser);
    setLoading(false);

    const handleStorageChange = () => {
      setLoading(true);
      const updatedUser = JSON.parse(localStorage.getItem("user"));
      setUser(updatedUser);
      setLoading(false);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const config = {
    tex: {
        inlineMath: [ ['$', '$'], ['\\(', '\\)'] ], // Inline math delimiters
        displayMath: [ ['$$', '$$'], ['\\[', '\\]'] ], // Block math delimiters
        processEscapes: true, // Allow escaping \$ for non-math dollar signs
        processEnvironments: true, // Allow math environments like \begin{align}
      },
  };

  return (
    <MathJaxContext config={config}>
    <Router>
      <Routes>
        <Route
          path="/login"
          element={user ? <Navigate to="/chat" /> : <Login />}
        />
        <Route
          path="/chat"
          element={user ? <Chat /> : <Navigate to="/login" />}
        />
        <Route path="/signup" element={<Accounts.Signup />} />
        <Route path="/verify-otp" element={<Accounts.VerifyOTP />} />
        <Route path="/set-password" element={<Accounts.SetPassword />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
    </MathJaxContext>
  );
};

export default App;
