import React, { useState } from "react";
import authService from "./authService";
import { useNavigate } from "react-router-dom";
import "./App.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    const sanitizedUsername = username.split("@")[0];

    try {
      const response = await authService.login(sanitizedUsername, password);
      if (response.access) {
        console.log("successful authentication");
        navigate("/chat", {
          state: { registrations: response.registrations },
        });
      } else {
        setMessage(response.detail || "Login failed");
      }
    } catch (error) {
      setMessage(error.toString());
    }
  };

  const handleSignupClick = () => {
    navigate("/signup");
  };

  const rootElement = document.documentElement;
  rootElement.classList.add('dark-mode');

return (
  <div
    className="Main"
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '98vh',
      textAlign: 'center',
      fontFamily: 'Arial, sans-serif',
    }}
  >

    <header className="Login-Header" style={{ marginBottom: '20px' }}>
      <h1 style={{ fontSize: '3rem', marginBottom: '10px' }}>CS Duck Mark1</h1>
      <img
        src="duck_image.gif"
        alt="Duck"
        style={{
          width: '10vw',
          borderRadius: '35px',
        }}
      />
    </header>


    <form
      onSubmit={handleLogin}
      style={{
        width: '100%',
        maxWidth: '400px',
        marginBottom: '20px',
      }}
    >
      <div style={{ marginBottom: '15px' }}>
        <label
          htmlFor="username"
          style={{
            display: 'block',
            fontWeight: 'bold',
            marginBottom: '5px',
          }}
        >
          Username:
        </label>
        <input
          type="text"
          name="username"
          title="The portion of your registered email before @, usually first.last"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          style={{
            width: '100%',
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ccc',
          }}
        />
      </div>
      <div style={{ marginBottom: '20px' }}>
        <label
          htmlFor="password"
          style={{
            display: 'block',
            fontWeight: 'bold',
            marginBottom: '5px',
          }}
        >
          Password:
        </label>
        <input
          type="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{
            width: '100%',
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ccc',
          }}
        />
      </div>
      <div>
        <button
          type="submit"
          style={{
            width: '100%',
            padding: '10px',
            borderRadius: '5px',
            backgroundColor: '#007bff',
            color: 'white',
            fontWeight: 'bold',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          Login
        </button>
      </div>
      {message && (
        <div style={{ marginTop: '10px', color: 'red' }}>{message}</div>
      )}
    </form>

    {/* Signup / Forgot Password Button */}
    <form
      style={{
        width: '100%',
        maxWidth: '400px',
      }}
    >
      <button
        onClick={handleSignupClick}
        className="signup-button"
        style={{
          width: '100%',
          padding: '10px',
          borderRadius: '5px',
          backgroundColor: '#6c757d',
          color: 'white',
          fontWeight: 'bold',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        Signup / Forgot Password
      </button>
    </form>
  </div>
);
};

export default Login;
