const login = async (username, password) => {
    const response = await fetch("api/token/", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
    });

    const data = await response.json();

    if (data.access) {
        localStorage.setItem("user", JSON.stringify(data));
        localStorage.setItem("userName", JSON.stringify(username));
        window.dispatchEvent(new Event("storage"));
    }

    return data;
};

function refreshToken() {
    const user = getCurrentUser();
    const refreshToken = user.refresh;

    if (!refreshToken) {
      console.error("No refresh token available.");
      return;
    }
  
    fetch('/api/token/refresh/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refresh: refreshToken }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.access) {
            user.access = data.access;
            localStorage.setItem("user", JSON.stringify(user));
        } else {
          console.error("Failed to refresh token.");
        }
      })
      .catch(error => {
        console.error("Error refreshing token:", error);
      });
}

function isTokenExpiringSoon() {
    const user = getCurrentUser();
    const token = user.access;
    if (!token) return false;
  
    const payload = JSON.parse(atob(token.split('.')[1]));
    const expTime = payload.exp * 1000;
    const now = Date.now();
  
    return expTime - now < 5 * 60 * 1000; // Check if the token expires in less than 5 minutes
}

const fetchQueryCount = async () => {
    const user = getCurrentUser();
    const userName = getCurrentUserName();
    const requestData = { userName };
    if (user && user.access) {
        const response = await fetch(
            "api/query-count/",
            {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + user.access,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestData),
            },
        );

        try {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            localStorage.setItem("queries", data.count);
            return data.count;
        } catch (e) {
            console.error(e);
        }
    }

    return -1;
};

const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("userName");
    window.dispatchEvent(new Event("storage"));
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const getCurrentUserName = () => {
    return JSON.parse(localStorage.getItem("userName"));
};

const authService = {
    login,
    logout,
    getCurrentUser,
    getCurrentUserName,
    fetchQueryCount,
    refreshToken,
    isTokenExpiringSoon
};

export default authService;
