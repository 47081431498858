// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';

// // Render the App component into the div with id 'root' in the index.html
// ReactDOM.render(<App />, document.getElementById('root'));

import React, { StrictMode } from "react";
// react DOM is a library that communicates with web browsers
import { createRoot } from "react-dom/client";
// import "./styles.css";

import App from "./App";

const root = createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
